import { z } from 'zod'
import { t } from '../../../utils'

export const thirdPartiesHapiHopemi18nFields = {
  username: t('hopem-hapi-client-identifier-username-please-note-that'),
  password: t('hopem-hapi-client-identifier-password-please-note-that'),
  key: t('hopem-hapi-client-identifier-key'),
  shouldSyncBuildingComplexesAsOneBuilding: t('third-party-sync-building-complexes-as-one-building'),
}

export const thirdPartiesHapiHopemSchemaUpdate = z.object({
  username: z.string().optional(),
  password: z.string().optional(),
  key: z.string().optional(),
  shouldSyncBuildingComplexesAsOneBuilding: z.boolean().optional().nullable().default(false),
})

export type ThirdPartiesHapiHopem = z.infer<typeof thirdPartiesHapiHopemSchemaUpdate>
