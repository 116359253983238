import { z } from 'zod'
import { i18n, t } from '../../utils'

export const CreateTaskResidenti18nFields = {
  category: t('category'),
  description: t('description'),
  pictures: t('pictures'),
  priorityStatus: t('priority'),
}

export const createTaskResidentSchema = z.object({
  category: z
    .string({ required_error: i18n.t('input:is-required', { field: CreateTaskResidenti18nFields.category }) })
    .min(1, { message: i18n.t('input:is-required', { field: CreateTaskResidenti18nFields.category }) }),
  description: z.string().nullable().optional(),
  priorityStatus: z.object({ id: z.string() }).optional(),
  pictures: z.any().nullable().optional(),
  attachments: z.any().nullable().optional(),
})

export type CreateTaskResidentSchemaType = z.infer<typeof createTaskResidentSchema>
